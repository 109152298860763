import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import {User} from '@app/shared/classes/models/user';
import { UserService } from '@app/shared/api_services/user/user.service';
import { map, filter, switchMap, mergeMap } from 'rxjs/operators';
import { UserAddress } from '@app/shared/classes/models/userAddress'

@Injectable({
    providedIn: 'root'
})

export class UserManager{
    constructor( private oUserService: UserService){

    }

    private observableUserAddress = new BehaviorSubject<Array<UserAddress>>(new Array<UserAddress>());

    getUser(){
        this.oUserService.getUser();
      }

    createAccount(oUser: User): Observable<any>{
        return this.oUserService.createAccount(oUser).pipe(map((response: any) => response));
    }

    login(oUser: User): Observable<any>{
        return this.oUserService.login(oUser).pipe(map((response: any) => response));
    }

    logout(oUser: User): Observable<any>{
        return this.oUserService.logout(oUser).pipe(map((response: any) => response));
    }

    changePassword(oUser: User): Observable<any>{
        return this.oUserService.changePassword(oUser).pipe(map((response: any) => response));
    }

    saveAddress(oUserAddress: UserAddress): Observable<any>{
        return this.oUserService.saveAddress(oUserAddress).pipe(map((response: any) => response));
    }

    updateAddress(oUserAddress: UserAddress): Observable<any>{
        return this.oUserService.updateAddress(oUserAddress).pipe(map((response: any) => response));
    }

    deleteAddress(oUserAddress: UserAddress): Observable<any>{
        return this.oUserService.deleteAddress(oUserAddress).pipe(map((response: any) => response));
    }

    getAddress(userId: number): Observable<Array<UserAddress>>{
        let lstUserAddress: Array<UserAddress> = null;
        //return this.oUserService.getAddress(userId).pipe(map((response: any) => response));
        this.oUserService.getAddress(userId).pipe(map((response: any) => response)).subscribe((data: any) => {
            if (Object.keys(data.UserAddress).length > 0) {
                lstUserAddress = [];
                
                for (let i = 0; i < data.UserAddress.length; i++) {               
                    let oUserAddress = new UserAddress();
                    oUserAddress.address = data.UserAddress[i].Address;
                    oUserAddress.address1 = data.UserAddress[i].Address1;
                    oUserAddress.city = data.UserAddress[i].City;
                    oUserAddress.contactEmail = data.UserAddress[i].ContactEmail;
                    oUserAddress.contactName = data.UserAddress[i].ContactName;
                    oUserAddress.contactPhone = data.UserAddress[i].ContactPhone;
                    oUserAddress.contactTypeID = data.UserAddress[i].ContactTypeID;
                    oUserAddress.country = data.UserAddress[i].Country;
                    oUserAddress.landmark = data.UserAddress[i].Landmark;
                    oUserAddress.mobileNo = data.UserAddress[i].MobileNo;
                    oUserAddress.state = data.UserAddress[i].State;
                    oUserAddress.stateID = data.UserAddress[i].StateID;
                    oUserAddress.userAddressID = data.UserAddress[i].UserAddressID;
                    oUserAddress.userID = data.UserAddress[i].UserID;
                    oUserAddress.zip = data.UserAddress[i].Zip;
                    lstUserAddress.push(oUserAddress);
                }
                lstUserAddress[0].isSelected = true;
                this.observableUserAddress.next(lstUserAddress);
                return this.observableUserAddress.asObservable();
            }
        });

        this.observableUserAddress.next(lstUserAddress);
        return this.observableUserAddress.asObservable();
    }
}