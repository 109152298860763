import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { HttpClientModule, HttpClient, HttpHeaders } from '@angular/common/http';
import { ProductCart } from '@app/shared/classes/models/productCart';
import { UpdateCartRequest } from '@app/shared/classes/messageContracts/request/updateCartRequest';

const httpOptions = {
  headers: new HttpHeaders({
      'Content-Type': 'application/json'
  }),

  params: {}
};

@Injectable({
  providedIn: 'root'
})
export class ProductService {

  apiUrl: string;
  
  constructor(private http: HttpClient) { }

  GetProductList(productCategoryId, productTypeId, isTopCategory, onFlashSale, searchText): Observable<any>{
    
   this.apiUrl = "http://ec2-65-0-121-107.ap-south-1.compute.amazonaws.com/FitTantra/api/Product/GetProductList?productCategory="+ productCategoryId+"&productType="+productTypeId+"&productId=0&isTopCategory=" + isTopCategory +"&onFlashSale=" + onFlashSale+ "&searchText=" + searchText;
   return this.http.get<any>(this.apiUrl);
 }

  getProductById(id){
    this.apiUrl = "http://ec2-65-0-121-107.ap-south-1.compute.amazonaws.com/FitTantra/api/Product/GetProductList?productCategory=0&productType=0&productId=" + id;
    return this.http.get<any>(this.apiUrl);
  }

  addToCart(oProductCart: ProductCart){
    
    let oUpdateCartRequest = new UpdateCartRequest();
    oUpdateCartRequest.cart = oProductCart;
    this.apiUrl = "http://ec2-65-0-121-107.ap-south-1.compute.amazonaws.com/FitTantra/api/Product/UpdateCart";
    return this.http.post<any>(this.apiUrl, oUpdateCartRequest);
  }

  deleteCart(oProductCart: ProductCart){
    
    let oUpdateCartRequest = new UpdateCartRequest();
    oUpdateCartRequest.cart = oProductCart;
    this.apiUrl = "http://ec2-65-0-121-107.ap-south-1.compute.amazonaws.com/FitTantra/api/Product/DeleteCart";
    return this.http.post<any>(this.apiUrl, oUpdateCartRequest);
  }

  getCart(): Observable<any>{
    this.apiUrl = "http://ec2-65-0-121-107.ap-south-1.compute.amazonaws.com/FitTantra/api/Product/GetProductCart";
    return this.http.get<any>(this.apiUrl);
  }

}
