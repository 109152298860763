
import { Gender } from '@app/shared/classes/models/commonModel/commonEnum'; 
export class User
{
    userId: string;
    userName: string;
    userEmail: string;
    userFirstName: string;
    userLastName: string;
    userMobileNumber: string;
    userPswd: string;
    confirmPassword: string;
    userTypeID: number;
    createdOrUpdatedBy: string;
    isActive: boolean;
    userGender: number;
    userChangePswd: string;

    constructor(){
        this.userId ='0';
        this.userName='';
        this.userEmail='';
        this.userFirstName='';
        this.userLastName ='';
        this.userMobileNumber='';
        this.userPswd='';
        this.confirmPassword='';
        this.userTypeID=0;
        this.createdOrUpdatedBy = '';
        this.isActive = true;
        this.userGender = Gender.Male;
        this.userChangePswd = '';
    }
}