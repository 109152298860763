import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Error } from '@app/shared/classes/error';

@Injectable({
  providedIn: 'root'
})
export class MessageService {

  public isLoading = new BehaviorSubject<Error>(this.oError);
  constructor(private oError :  Error) { }
}
