import { Injectable, ErrorHandler, Injector } from '@angular/core';
import { Router } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';
import { NotificationService } from './notification.service';
import { MessageService} from '@app/shared/services/message.service';
import { Error } from '@app/shared/classes/error';



@Injectable({
    providedIn: 'root'
})

export class ErrorService implements ErrorHandler {
    
    constructor(private injector: Injector, private messageNotification: NotificationService, private messageService: MessageService, private error : Error) { }

    
    handleError(error: any) {
        const router = this.injector.get(Router);
        if (Error instanceof HttpErrorResponse) {
        //    ////console.log(error.status);
            this.messageNotification.showError(error.message);
          // //console.error("an error occurred PPPPPPPPPPPPPPPPPPPPPPPPP");
        }
        else {
           // //console.error("an error occurred here broo");
          
           this.error.isError= true;
           this.error.errorMessage = error.message;
           this.messageService.isLoading.next(this.error);
           // this.messageNotification.showError(error.message);
           // ////console.log(error);
        }
        
        //router.navigate(['error']);
    }
}

// export class ErrorService{
//     public isLoading = new BehaviorSubject(false);

//   constructor() {
//       alert("hi")
//       ////console.log(this.isLoading)
//    }
// }