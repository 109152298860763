import { Component, OnInit, Injectable, PLATFORM_ID, Inject, Output, EventEmitter } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { Observable } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { ProductService } from "../../services/product.service";
//import { Product } from "../../classes/product";
import { Product} from '@app/shared/classes/models/product';
import { ProductCartItem } from '@app/shared/classes/models/productCartItem';
import { ProductManager } from '@app/shared/manager/product/productManager';
import { map, filter, first } from 'rxjs/operators';
import { SearchService } from '@app/shared/services/searchService';
import { Router } from '@angular/router';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss']
})
export class SettingsComponent implements OnInit {

  public products: Product[] = [];
  public search: boolean = false;
  public productCartItems = new Array<ProductCartItem>();
  serachText: string;
  
  
  public languages = [{ 
    name: 'English',
    code: 'en'
  }, {
    name: 'Hindi',
    code: 'hi'
  }];

  public currencies = [{
    name: 'Euro',
    currency: 'EUR',
    price: 0.90 // price of euro
  }, {
    name: 'Rupees',
    currency: 'INR',
    price: 70.93 // price of inr
  }, {
    name: 'Pound',
    currency: 'GBP',
    price: 0.78 // price of euro
  }, {
    name: 'Dollar',
    currency: 'USD',
    price: 1 // price of usd
  }]

  constructor(@Inject(PLATFORM_ID) private platformId: Object,
    private translate: TranslateService,
    public productService: ProductService, private oProductManager: ProductManager, private oSearchService: SearchService, private router: Router) {
    this.productService.cartItems.subscribe(response => this.products = response);
  }

  ngOnInit(): void {
    this.serachText = "";
    //this.oSearchService.observableSearchService.next(this.serachText);
    this.getCart();
  }

  getCart(){
    
    
    this.oProductManager.getCart().pipe(map((response: any) => response), first(val => val != null)).subscribe((data: any) => {
      
      //console.log(this.productCartItems);
      this.productCartItems = data.cart.productCartItems;
     
    })
  }

  searchBy(){
   // alert('Settings:' + this.serachText)
    //this.oSearchService.observableSearchService.next(this.serachText);
    //this.router.navigateByUrl('/shop');
    if(this.serachText != ''){
      this.router.navigate(['/shop'], {
        queryParams: {
          searchName: this.serachText
        },
        queryParamsHandling: 'merge',
      });
      this.serachText ='';
    }
    
  }

  searchToggle(){
    this.search = !this.search;
  }

  changeLanguage(code){
    if (isPlatformBrowser(this.platformId)) {
      this.translate.use(code)
    }
  }

  get getTotal(): Observable<number> {
    return this.productService.cartTotalAmount();
  }

  removeItem(product: any) {
    this.productService.removeCartItem(product);
  }

  changeCurrency(currency: any) {
    this.productService.Currency = currency
  }

}
