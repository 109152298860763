import { BrowserModule } from '@angular/platform-browser';
import { NgModule, ErrorHandler } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { LoadingBarHttpClientModule } from '@ngx-loading-bar/http-client';
import { LoadingBarRouterModule } from '@ngx-loading-bar/router';
import { ToastrModule } from 'ngx-toastr';
import { TranslateLoader, TranslateModule} from '@ngx-translate/core';
import { TranslateHttpLoader} from '@ngx-translate/http-loader';
import { SharedModule } from './shared/shared.module';
import { AppRoutingModule } from './app-routing.module';

import { AppComponent } from './app.component';
import { ShopComponent } from './shop/shop.component';
import { PagesComponent } from './pages/pages.component';
import { ElementsComponent } from './elements/elements.component';

import { ErrorService} from '@app/shared/services/error.service';
import { NotificationService} from '@app/shared/services/notification.service';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { InterceptorService } from '@app/shared/services/interceptor.service';
import {MessageService} from '@app/shared/services/message.service'
import {Error} from '@app/shared/classes/error';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import{ SearchService} from '@app/shared/services/searchService';

// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient) {
   return new TranslateHttpLoader(http, "./assets/i18n/", ".json");
}

@NgModule({
  declarations: [
    AppComponent,
    ShopComponent,
    PagesComponent,
    ElementsComponent
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    BrowserAnimationsModule,
    HttpClientModule,
    NgbModule,
    MatSnackBarModule,
    LoadingBarHttpClientModule,
    LoadingBarRouterModule,
    ToastrModule.forRoot({
      timeOut: 3000,
      progressBar: false,
      enableHtml: true,
    }),
    TranslateModule.forRoot({
        loader: {
            provide: TranslateLoader,
            useFactory: HttpLoaderFactory,
            deps: [HttpClient]
        }
    }),
    SharedModule,
    AppRoutingModule
  ],
  providers: [NotificationService, MessageService, Error, SearchService,
    {
        provide: ErrorHandler,
        useClass: ErrorService,
    },
    {provide: LocationStrategy, useClass: HashLocationStrategy},
   {
            provide: HTTP_INTERCEPTORS,
            useClass: InterceptorService,
            multi: true
    }],
  bootstrap: [AppComponent]
})
export class AppModule { }
